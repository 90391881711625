import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:{},
    isLoading:false,
    refCount:0,
  },
  mutations: {

    loading(state, isLoading) {
      console.log({isLoading})
      if (isLoading) {
        state.refCount++;
        state.isLoading = true;
      } else if (state.refCount > 0) {
        state.refCount--;
        state.isLoading = (state.refCount > 0);
      }
    },
  },
  actions: {
    employeMe({state}){
      axios.get('/employee/me',{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then(res=>{
            state.user=res.data
          })
    }
  },
  modules: {
  }
})
