<template>
  <div class="home">
    <top-header :btn_name="'Ishga keldim'" :is_chech_out="is_out" :btn_icon="btn_icon" @edit-todo="attendances()" @edit-todo1="is_modal=true" :btn_name_1="'Ishdan ketdim'" :btn_icon1="btn_icon1"></top-header>
    <Header></Header>
    <div class="table_container">
      <div class="row">
        <div class="col-xl-6">
          <div id="chart">
            <apexchart type="pie" width="100%" height="100%" :options="chartOptions" :series="series"></apexchart>
          </div>
        </div>
        <div class="col-xl-6">
          <div id="chart">
            <apexchart type="bar" width="100%" height="100%" :options="chartOptions1" :series="series1"></apexchart>
          </div>
        </div>
        <div class="col-xl-6">
          <div id="chart">
            <apexchart type="area" width="100%" height="100%" :options="chartOptions2" :series="series2"></apexchart>
          </div>
        </div>
        <div class="col-xl-6">
          <div id="chart">
            <apexchart type="polarArea" width="100%" height="100%" :options="chartOptions3" :series="series3"></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../components/Header";
import TopHeader from "../components/TopHeader";

export default {
  components: {TopHeader, Header},
  data(){
    return{
      is_modal:false,
      btn_icon:" <svg width=\"18\" height=\"18\" viewBox=\"0 0 18 18\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
          "            <path d=\"M9 0.875C4.52002 0.875 0.875 4.52002 0.875 9C0.875 13.48 4.52002 17.125 9 17.125C13.48 17.125 17.125 13.48 17.125 9C17.125 4.52002 13.48 0.875 9 0.875ZM9 2.125C12.8037 2.125 15.875 5.19629 15.875 9C15.875 12.8037 12.8037 15.875 9 15.875C5.19629 15.875 2.125 12.8037 2.125 9C2.125 5.19629 5.19629 2.125 9 2.125ZM8.375 5.25V8.375H5.25V9.625H8.375V12.75H9.625V9.625H12.75V8.375H9.625V5.25H8.375Z\" fill=\"white\"/>\n" +
          "          </svg>",
      check_date:[],
      btn_icon1:"\n" +
          "<svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
          "<path d=\"M10 1.875C5.52002 1.875 1.875 5.52002 1.875 10C1.875 14.48 5.52002 18.125 10 18.125C14.48 18.125 18.125 14.48 18.125 10C18.125 5.52002 14.48 1.875 10 1.875ZM10 3.125C13.8037 3.125 16.875 6.19629 16.875 10C16.875 13.8037 13.8037 16.875 10 16.875C6.19629 16.875 3.125 13.8037 3.125 10C3.125 6.19629 6.19629 3.125 10 3.125ZM6.25 9.375V10.625H13.75V9.375H6.25Z\" fill=\"#505D69\"/>\n" +
          "</svg>\n",
      is_out:true,
      series: [44, 55, 13, 43, 22],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      series1: [{
        data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380, 1200, 1380]
      }],
      chartOptions1: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ['Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'Iyun', 'Iyul',
            'Avgust', 'Sentabr', 'Oktabr', 'Noyabr', 'Dekabr'
          ],
        }
      },
      series2: [{
        name: "STOCK ABC",
      }],
      chartOptions2: {
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },

        title: {
          text: 'Fundamental Analysis of Stocks',
          align: 'left'
        },
        subtitle: {
          text: 'Price Movements',
          align: 'left'
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          opposite: true
        },
        legend: {
          horizontalAlign: 'left'
        }
      },
      series3: [14, 23, 21, 17, 15, 10, 12, 17, 21],
      chartOptions3: {
        chart: {
          type: 'polarArea',
        },
        stroke: {
          colors: ['#fff']
        },
        fill: {
          opacity: 0.8
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },



    }
  },
  methods:{
    attendances(){
      var todayDate = new Date().toISOString().slice(0, 10);
        this.$axiosDefault.post(`/employees/${this.$store.state.user.id}/attendances/`,{
              "check_in":new Date(),
              "date":todayDate,
              "employee":this.$store.state.user.id
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            })
            .then(res=>{
              console.log(res)
              this.$axiosDefault.get('/employee/me',{
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
              })
                  .then(ress=>{
                    console.log(ress)
                    this.$axiosDefault.get(`employees/${ress.data.id}/attendances`,
                        {
                          headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                          }
                        })
                        .then(res=>{
                          this.check_date=res.data
                          if(this.check_date[this.check_date.length-1].check_out!=null){
                            this.is_out=true
                          }
                          else {
                            this.is_out=false
                          }
                        })
                  })
            })
      .catch(()=>{
        this.$toast("Bugunlik ish vazifasini bajarib bo'ldiz maroqli hordiq tilaymiz", {
          timeout: 10000,
          type:"error"
        });
      })
      },
    check_out(){
      var todayDate = new Date().toISOString().slice(0, 10);
      this.$axiosDefault.patch(`/employees/${this.$store.state.user.id}/attendances/${this.check_date[this.check_date.length-1].id}/`,{
            "check_out":new Date(),
            "date":todayDate,
            "employee":this.$store.state.user.id
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(res=>{
            console.log(res)
            this.$axiosDefault.get('/employee/me',{
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            })
                .then(()=>{
                  localStorage.removeItem('token')
                  this.$router.push('/login')
                })
          })
    }

  },
  mounted() {
 this.$axiosDefault.get('/employee/me',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
        .then(ress=>{
          console.log(ress)
          this.$axiosDefault.get(`employees/${ress.data.id}/attendances`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
              })
              .then(res=>{
                this.check_date=res.data
                if(this.check_date[this.check_date.length-1].check_out!=null){
                  this.is_out=true
                }
                else {
                  this.is_out=false
                }
              })
        })
  }
}
</script>
