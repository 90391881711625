import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false
import axios from 'axios'

axios.defaults.baseURL = "https://api.localhr.itic.uz/api/v1/";
Vue.prototype.$axiosDefault = axios
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor, {})
import alanBtn from '@alan-ai/alan-sdk-web';
alanBtn({
  key: '12d2ba190256595f3d3180338662bf382e956eca572e1d8b807a3e2338fdd0dc/stage',
  onCommand: () => {
      window.open("https://erp.sport.uz/");
  },
});
import VueHtml2pdf from 'vue-html2pdf'
Vue.use(VueHtml2pdf)

import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  // You can set your default options here
};


Vue.use(Toast, options);
import './assets/css/styles.css'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
