<template>
  <div id="app">
        <div class="loader" v-if="isLoading" >
          <b-spinner variant="black" ></b-spinner>
        </div>
    <router-view/>
  </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
  components: {

  },
  mounted() {
  },
  computed: {
    ...mapState(['isLoading', 'refCount'])
  },
  created() {
    this.$axiosDefault.interceptors.request.use((config) => {
      this.$store.commit('loading', true);
      return config;
    }, (error) => {
      this.$store.commit('loading', false);
      return Promise.reject(error);
    });
    this.$axiosDefault.interceptors.response.use((response) => {
      this.$store.commit('loading', false);
      return response;
    }, (error) => {
      this.$store.commit('loading', false);
      return Promise.reject(error);
    });
  }
}
</script>
<style>
.loader {
  position: fixed;
  width: 100vw;
  height: 106%;
  background:  rgba(255, 255, 255,0.7);
  z-index: 2222;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ring{
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}
@keyframes ring {
  0%{
    transform: rotate(0deg);
    box-shadow: 1px 5px 2px #e65c00;
  }
  50%{
    transform: rotate(180deg);
    box-shadow: 1px 5px 2px #18b201;
  }
  100%{
    transform: rotate(360deg);
    box-shadow: 1px 5px 2px #0456c8;
  }
}
.ring::before{
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(255,255,255,.3);
}
.loader span{
  color: #737373;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 200px;
}
</style>
